import React from 'react'
import { Data } from './data'
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from 'swiper/react';
import '../css/contents.css'
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';

const Content = () => {
    return (
        <div className='mainwrap'>
            <div className='contentType'>action</div>
            <Swiper
                spaceBetween={7}
                centeredSlides={true}
                slidesPerView={7}
                loop={true}
                autoplay={{ delay: 5000 }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}

            >
                {Data.filter(name=>name.type==="action"&& !name.trending).map((movie) => {
                    return(

                        <SwiperSlide>
                            <Link to="/deacription" state={movie} >
                                <div className='imgWrap'>
                                    <img className='moviesInage' src={movie.image} alt={movie.title} title={movie.title} />
                                </div>

                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <div className='contentType'>Series</div>
            <Swiper
                spaceBetween={7}
                centeredSlides={true}
                slidesPerView={7}
                loop={true}
                autoplay={{ delay: 5000000 }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}

            >
                {Data.filter(name=>name.type==="series"&& !name.trending).map((movie) => {
                    return(

                        <SwiperSlide>
                            <Link to="/deacription" state={movie} >
                                <div className='imgWrap'>
                                    <img className='moviesInage' src={movie.image} alt={movie.title} title={movie.title} />
                                </div>

                            </Link>
                        </SwiperSlide>
                    )
                })}
            </Swiper>
        </div>
    )
}

export default Content
