import React, { useState } from 'react'
import logo from '../images/logo.png'
import "../css/HeaderFooter.css"
import { Link,useNavigate } from "react-router-dom";



const Header = () => {
    const navigate = useNavigate();

    const handleKeyPress = (event)=>{
        if(event.key === 'Enter'){
            event.preventDefault();
            console.log("chal gya ",event.target.value);
            navigate('/search',{state:{result:event.target.value}})
            event.target.value = ""
          }
          else{

          }
    }

    return (
        <div className="mainHeader">
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="container-fluid">
                    <Link to="/" className="navbar-brand" ><img className="logo" src={logo} alt="" /></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/movie" className="nav-link active" aria-current="page">Movies</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/series" className="nav-link" >Series</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/mylist" className="nav-link" >My List</Link>
                            </li>
                            
                            
                        </ul>
                        <form className="d-flex">
                            <input className=" me-2  searchbox"  placeholder="Search" aria-label="Search"   onKeyPress={handleKeyPress}/>
                            <Link to="/login"><button className="btn ms-3 loginbtn" >Login</button></Link>
                        </form>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Header
