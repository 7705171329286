import React from 'react'
import '../css/HeaderFooter.css'

const Footer = () => {
  return (
    <div>
        <div className='footer'>
            OYLA &copy; All rights reserved   
        </div>
    </div>
  )
}

export default Footer
