import './App.css';
import ReactDOM from "react-dom/client";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './Components/Home';
import Description from './Components/Description';
import Header from './Components/Header';
import Player from './Components/Player';

import Footer from './Components/Footer';
import MyList from './Components/MyList';
import Movie from './Components/Movie';
import Series from './Components/Series';
import Search from './Components/Search';
import Login from './Components/Login'


function App() {
  return (
    <>
      <BrowserRouter>
      <Header />
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/mylist" element={<MyList/>} />
          <Route path="/movie" element={<Movie/>} />
          <Route path="/search" element={<Search/>} />
          <Route path="/series" element={<Series/>} />
          <Route path="/login" element={<Login/>} />
          <Route exact path="/deacription" element={<Description props={{hello:'zdxzd'}} />} />
          <Route exact path="/player" element={<Player props={{hello:'zdxzd'}} />} />
         
        </Routes>
        <Footer/>
      </BrowserRouter>
      
    </>
  );
}

export default App;
