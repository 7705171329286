import React from 'react'
import '../css/contents.css'
import { Data } from './data'
import { Link } from "react-router-dom";
import Content from './Content';
// import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/autoplay';


const Home = (props) => {
    SwiperCore.use([Autoplay]);
    
    return (
        <div>
            
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                loop={true}
                autoplay={{ delay: 5000 }}
                navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
            >
                {Data.filter((item) => item.trending).map((movie,key) => {
                    return (
                        <SwiperSlide>
                            <Link to="/deacription" state={movie} >
                            <div className='carousel-container'>
                                <div className='carousel'>
                                    <div className='slider'>
                                        <div className='slider-content'>
                                            <h1 className='movie-title'>{movie.title}</h1>
                                            <p className='movie-desc'>{movie.Desc}
                                            </p>
                                        </div>
                                        <img src={movie.image} alt="" />
                                    </div>
                                </div>
                            </div>
                            </Link> 
                        </SwiperSlide>
                    )
                })}
            </Swiper>
            <Content />
        </div>
    )
}

export default Home




