import React from 'react'
import {Data} from './data'
import { Link } from "react-router-dom";

import '../css/contents.css'

const Series = () => {
  return (
    <div className='moviewrap'>
       <h2 >Series</h2>
      <div className='movies'>
        {Data.filter((item)=> item.type === "series").map((movie)=>{
          return(
            <Link to="/deacription" state={movie} >

              <div className='imgMovie'>
                  <img  src={movie.image} alt={movie.title} title={movie.title} />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Series
