import React from 'react'
import ReactPlayer from 'react-player'
import {useLocation} from 'react-router-dom'
import '../css/contents.css'


const Player = (props) => {
    const { state }  = useLocation()
    const width = "100%"
    const height = "80vh"


  return (
    <div className='d-flex justify-content-center'>
        
      <ReactPlayer
                width={width}
                height={height}
                playing={true}
                url={state}
            />
    </div>
  )
}

export default Player
