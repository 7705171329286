import React from 'react'
import '../css/contents.css'
import { Data } from './data'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper';


import { Link, useLocation } from 'react-router-dom'

const Description = (props) => {
    const { state } = useLocation();
    const type = state.type

    const bgdesc={
        backgroundImage:`url(${state.image})`,
  
    }
    const mainhight ={
        position:"relative",
        height:"100%"

    }
    const addLast = ()=>{
        
    }
    return (
        <div style={mainhight}> 
            <div  style={bgdesc} className='bgimage'></div>
            <div className='carousel-container'>
                <div className='carousel'>
                    <div className='slider'>
                        <div className='slider-content'>
                            <h1 className='movie-title'>{state.title}</h1>
                            <p className='movie-detail'>2 hr 16 min 2021 </p>
                            <p className='movie-desc'>{state.Desc}
                            </p>
                            <div className='watch-btns'>
                                <Link to="/player" state={state.video}>
                                    <div className='d-flex align-items-center'>
                                        <div className='paly-icon'></div>
                                        <p>Watch Movie</p>
                                    </div>
                                </Link>
                                <div className='d-flex align-items-center' onClick={addLast}>
                                    <div className='add-icon'></div>
                                    <p>Watchlist</p>
                                </div>
                            </div>
                        </div>
                        <img src={state.image} alt="" />
                    </div>
                </div>
            </div>
            <div className='mainwrap mb-0'>
                <p className='contentType'>More like this</p>
                <Swiper
                    spaceBetween={7}
                    centeredSlides={true}
                    slidesPerView={7}
                    loop={true}
                    autoplay={{ delay: 5000000 }}
                    navigation={true}
                    modules={[Autoplay, Pagination, Navigation]}

                >
                    {Data.filter(name=>name.type===type&& !name.trending).map((movie) => {
                        return(

                            <SwiperSlide>
                                <Link to="/deacription" state={movie} >
                                    <div className='imgWrap'>
                                        <img className='moviesInage' src={movie.image} alt={movie.title} title={movie.title} />
                                    </div>

                                </Link>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
            </div>
        </div>
    )
}


export default Description
