import React from 'react'
import {Data} from './data'
import { Link } from "react-router-dom";

import '../css/contents.css'
const Movie = () => {
  return (
    <div className='moviewrap'>
      <h2 >Movies</h2>
      <div className='movies'>
        {Data.filter((item)=> item.type === "action").map((movie)=>{
          return(
            <Link to="/deacription" state={movie} >

              <div className='imgMovie'>
                  <img  src={movie.image} alt={movie.title} title={movie.title} />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Movie
