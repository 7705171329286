export const Data = [
    {
      title: "moon night",
      Desc: "Mild-mannered Steven Grant has dissociative identity disorder and shares a body with a mercenary.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/1511/1161511-h-a103f5d4c916",
      video:"https://www.youtube.com/watch?v=nPE5ATJRLKk",
      type:'series',
      trending:true,
    },
    {
      title: "doctor strange",
      Desc: 'Dr. Strange faces a difficult choice of whether to return to his life of luxury or to protect the world as the most powerful sorcerer in existence.',
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/4619/674619-h",
      trending:true,
      type:'action',
      video:"https://www.youtube.com/watch?v=-mjWrrKgwJM",

    },
    {
      title: "no exit",
      Desc: "Darby Thorne, a young woman who is stranded by a blizzard, stumbles across an abducted girl hidden in a van, leading her to a terrifying life-or-death struggle.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/4075/1194075-h-58be4f64d4bb",
      trending:true,
      type:'action',
      video:"https://www.youtube.com/watch?v=7qu5uPjH8rw",

    },
    {
      title: "abhi iska naam nhi pta",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_m_1x/sources/r1/cms/prod/7626/177626-h",
      trending:true,
      type:'action',
      video:"https://www.youtube.com/watch?v=3-RW3nUHs8g",

    },

    {
      title: "tha thursday",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_1_5x/sources/r1/cms/prod/4622/1114622-v-6aa53dbe426f",
      type:'series',
      video:"https://www.youtube.com/watch?v=7O_96yftBRc",

    },
    {
      title: "rudra",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_1_5x/sources/r1/cms/prod/2733/1122733-v-5df3bdae2d7a",
      type:'series',
      video:"https://www.youtube.com/watch?v=FyuODiTjoFs",

    },
    {
      title: "baaghi 3",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_1_5x/sources/r1/cms/prod/6536/846536-v",
      type:'action',
      video:"https://www.youtube.com/watch?v=jQzDujMzfoU"
    },
    {
      title: "halo",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://v3img.voot.com/resizeMedium,w_810,h_1080/v3Storage/assets/5-1651477310738.png",
      type:'series',
      video:"https://www.youtube.com/watch?v=fizKm-qfvqg",

    },
    {
      title: "london files",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://v3img.voot.com/resizeMedium,w_810,h_1080/v3Storage/assets/lf-3x4-mega-premiere-1650516037765.jpg",
      type:'series',
      video:"https://www.youtube.com/watch?v=WO5LLgT5YmE",
    },
    {
      title: "the kings man",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_3x/sources/r1/cms/prod/5029/1185029-v-6fab2d72a68f",
      type:'action',
      video:"https://www.youtube.com/watch?v=UWrrbBt0ej0",
    },
    {
      title: "bang bang",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_3x/sources/r1/cms/prod/old_images/vertical/MOVIE/4503/1000034503/1000034503-v",
      type:'action',
      video:"https://www.youtube.com/watch?v=MGXQ8bE6lW4",
    },
    {
      title: "baby",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_3x/sources/r1/cms/prod/7416/957416-v",
      type:'action',
      video:"https://www.youtube.com/watch?v=-Yu_2nyOP5o",

    },
    {
      title: "hit monkey",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_3x/sources/r1/cms/prod/4215/1104215-v-0adb7d7fea25",
      type:'series',
      video:"https://www.youtube.com/watch?v=gb72HBB1QbA",

    },
    {
      title: "aarya",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_3x/sources/r1/cms/prod/948/1090948-v-5d7d08b8d6ce",
      type:'series',
      video:"https://www.youtube.com/watch?v=KSVD5w1zw0g",

    },
    {
      title: "deadpool 2",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_1_5x/sources/r1/cms/prod/7413/297413-v",
      type:'action',
      video:"https://www.youtube.com/watch?v=KhI4xzwYImA",

    },
    {
      title: "captain america ",
      Desc: "In the hidden high-security government laboratory, lonely Elisa's life changes forever after discovering a secret classified experiment.",
      image: "https://img1.hotstarext.com/image/upload/f_auto,t_web_vl_1_5x/sources/r1/cms/prod/4528/674528-v",
      type:'action',
      video:"https://www.youtube.com/watch?v=IcZZ-29FumQ",
    },
]