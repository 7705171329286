import React, { useState, useEffect } from 'react'
import { Data } from './data'
import { Link ,useLocation} from "react-router-dom";


const Search = () => {
    
    const { state } = useLocation();
    console.log(state.result,"from state");
    const check = state.result
    const [result,setResult] = useState(state.result)
    console.log(result);

    const reRender= ()=>{
        setResult(state.result)
        console.log(result,'inFunction');
    }
    if(check!=result){
        reRender()
    }
    // const [loader,setLoader] = useState(false)
    // setTimeout(()=>{
    //     setLoader(true) 
    //    },2000)
    


    return (
        <div className='moviewrap'>
            {/* {loader?"true":"false"} */}
            <h2 >Search Result </h2>
            <div className='movies'>
                {Data.filter((item) => item.title.match(result) || item.type.match(result)).map((movie) => {
                    return (
                        <Link to="/deacription" state={movie} >

                            <div className='imgMovie'>
                                <img src={movie.image} alt={movie.title} title={movie.title} />
                            </div>
                        </Link>
                    )
                })}
            </div>

        </div>
    )
}

export default Search
